@import "~bootstrap/scss/bootstrap";

@import 'form.scss';
@import 'fonts.scss';


* {
  --bs-primary: #d40605;
  --bs-primary-rgb: 212,6,5;
  --bs-secondary: #292930;
  --bs-secondary-rgb: 41,41,48;
  --bs-success: #505050;
  --bs-success-rgb: 80,80,80;
  --bs-info: #a0a0a0;
  --bs-info-rgb: 160,160,160;
  --bs-warning: #d40605;
  --bs-warning-rgb: 212,6,5;
  --bs-danger: #d40605;
  --bs-danger-rgb: 212,6,5;
  --bs-light: #c8c8c8;
  --bs-light-rgb: 200,200,200;
  --bs-body-color: #292930;
  --bs-dark: #292930;
  --bs-dark-rgb: 41,41,48;
  --bs-body-bg: #f6f6f6;
  --bs-body-font-weight: normal;
}

body {
  color: #292930;
  font-family: 'Toyota Type';
}

#lp .container-fluid {
  padding: 0px;
  overflow: hidden;
}

#tylp.container-fluid {
  background: #ffffff;
}

#header {
  height: 82px;
  background: var(--bs-white);
}

@media (max-width: 575px) {
  #header {
    padding-left: 15px;
  }
}

@media (max-width: 575px) {
  #tylp #header {
    padding-left: 0px;
  }
}

@media (max-width: 326px) {
  #header img {
    max-width: 230px;
  }
}

#submit_mainpage, .toy_submit {
  -webkit-transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,box-shadow -0.3s ease-in-out;
  transition: color 0.3s ease-in-out,background-color 0.3s ease-in-out,border-color 0.3s ease-in-out,box-shadow 0.3s ease-in-out,-webkit-box-shadow 0.3s ease-in-out;
  background: var(--bs-secondary);
  font-size: 21px;
  border-radius: 0;
  box-shadow: 0px 7px 7px 0px rgba(41,41,48,0.29);
  max-width: 340px;
  padding-top: 10px;
  padding-bottom: 14px;
}

#submit_mainpage:hover, .toy_submit:hover {
  background-color: var(--bs-success)!important;
  /*color: #393943;*/
  /*border-color: #393943!important;*/
  box-shadow: none!important;
}

.tooltip-inner {
  background-color: white !important;
  color: var(--bs-success) !important;
  border-radius: 0 !important;
  /*box-shadow: 4px 7px 4px 0px rgba(161, 161, 161, 1);*/
  box-shadow: 0px 0px 12px 0px rgba(41,41,48,0.29)!important;
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 300px;
  --bs-tooltip-padding-x: 0.7rem!important;
  --bs-tooltip-padding-y: 0.7rem!important;
  --bs-tooltip-margin: 10px!important;
  --bs-tooltip-font-size: 0.875rem;
  /*--bs-tooltip-color: #fff;*/
  --bs-tooltip-bg: rgba(255,255,255,0)!important;
}

h1 {
  padding-bottom: 2px;
}

.boxik {
  background: white!important;
  box-shadow: 0px 0px 20px 4px var(--bs-gray-300);
}

.boxik img {
  width: 90px;
  position: relative;
}

.boxik div ~ div {
  padding-top: 18px;
  padding-right: 77px;
  padding-bottom: 4px;
  padding-left: 10px;
}

.boxik p {
  color: var(--bs-secondary);
  font-family: 'Toyota Type Book';
  line-height: 150%;
  font-size: 15px;
  margin-bottom: 11px;
}

.boxik .lead {
  font-weight: bold;
  margin-bottom: 6px;
}

a {
  color: var(--bs-secondary);
}

#mecha {
  margin-top: -82px;
  width: 100%;
  padding-left: 4.3vw;
}

@media (max-width: 575px) {
  #mecha {
    margin-top: 0px!important;
    padding-left: 0px;
    margin-bottom: 20px;
  }
}

@media (max-width: 800px) {
  #mecha_col {
    padding: 0px!important;
    padding-left: 4.3%;
  }
}

#mecha_col {
  padding: 0px!important;
}

ul {
  padding-left: 21px;
}

li {
  padding-bottom: 4px;
  color: var(--bs-secondary);
  font-family: 'Toyota Type Book';
  font-size: 15px;
  line-height: 150%;
}

li::marker {
  font-size: 16px;
}

.form_section {
  /*padding: 20px 0px;*/
  padding-bottom: 10px;
}

.form_section hr {
  width: 20vw;
  color: var(--bs-primary);
  height: 3px;
  opacity: 1;
  border-width: 3px;
}

@media (max-width: 574px) {
  .boxiki {
    padding-left: 4.3vw;
    padding-right: 4.3vw;
    padding-top: 20px;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 575px) {
  #form_container {
    padding-left: 5vw!important;
    padding-right: 7vw!important;
  }
}

@media (max-width: 574px) {
  #intro_col hr {
    width: 20vw;
  }
}

@media (max-width: 574px) {
  .form_section hr {
    width: 20vw;
  }
}

@media (max-width: 574px) {
  .boxik {
    margin-bottom: 16px!important;
  }
}

@media (max-width: 574px) {
  .boxik.col {
    box-shadow: 0px 0px 20px 4px var(--bs-gray-300);
    background: top / auto no-repeat;
    color: var(--bs-gray-500);
    margin-bottom: 30px;
    padding-right: 0;
  }
}

@media (min-width: 575px) {
  .boxiki {
    padding-left: 5vw;
    padding-right: 4.3vw;
    padding-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
  }
}

@media (min-width: 575px) {
  #form_container {
    padding-left: 5.3vw!important;
    padding-right: 5.3vw!important;
  }
}

@media (min-width: 575px) {
  .form_section hr {
    width: 15vw;
  }
}

@media (min-width: 1166px) {
  .boxiki {
    padding-left: 5vw;
    padding-right: 4.3vw;
    padding-top: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
  }
}

@media (min-width: 1200px) {
  #form_container {
    padding-left: 13vw!important;
    padding-right: 14vw!important;
    /*padding-top: 30px;*/
    /*grid-template-columns: repeat(3, 1fr);*/
    /*grid-column-gap: 2vw;*/
  }
}

@media (min-width: 1166px) {
  .form_section hr {
    width: 6vw;
  }
}

@media (min-width: 1400px) {
  .boxiki {
    padding-left: 17.3vw;
    padding-right: 16.8vw;
    padding-top: 30px;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 2vw;
    grid-row-gap: 2vw;
  }
}

@media (min-width: 1400px) {
  #form_container {
    padding-left: 17vw!important;
    padding-right: 14vw!important;
    /*padding-top: 30px;*/
    /*grid-template-columns: repeat(3, 1fr);*/
    /*grid-column-gap: 2vw;*/
  }
}

@media (min-width: 1400px) {
  #intro_col hr, .ty-copy hr {
    width: 10vw!important;
  }
}

@media (max-width: 500px) {
  #submit_mainpage, .toy_submit {
    width: 100%!important;
    max-width: 100%!important;
  }
}

.boxiki #form_container {
  grid-auto-rows: 1fr;
}

#header div {
  /*background: var(--bs-white);*/
  height: 82px;
  padding-top: 20px;
}

#intro_col {
  padding-right: 30px;
  margin: 10px 0px;
}

#intro_col h1, .ty-copy h1 {
  color: #292930;
  font-size: 36px;
}

#intro_col p, .ty-copy p {
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Toyota Type Book';
}

#intro_col hr {
  width: 20vw;
  color: var(--bs-primary);
  height: 3px;
  opacity: 1;
  border-width: 3px;
}

#legale {
  margin-top: 50px;
}

#legale p {
  color: var(--bs-secondary);
  font-size: 13px;
  line-height: 130%;
  font-family: 'Toyota Type Book';
  /*font-weight: bold;*/
}

.form_section {
  margin-top: 30px;
}

.standalone_row {
  width: 100%;
}

.standalone_col {
  /*padding-bottom: 0px;*/
}

.add_picture {
  /*padding-right: 40px;*/
  /*padding-left: 40px;*/
  /*margin-bottom: 30px;*/
  padding: 6px 39px 9px;
  margin-right: 10px;
  /*margin-bottom: 17px;*/
}

.input_with_label.and_button {
  margin-bottom: 20px;
}

.picture_name {
  border: solid 1px var(--bs-info);
  padding: 5px 4px 9px 18px;
  max-height: 40px!important;
  margin: 10px 10px 10px 0px;
  background-color: #ffffff;
}

.picture_name p {
  padding: 0px;
  margin: 0px;
  font-size: 13px;
}

.ico_close {
  margin-top: -6px;
  margin-left: 11px;
}

.add_picture_div {
  margin-right: 10px;
}

@media (max-width: 418px) {
  .add_picture_div {
    margin-bottom: 11px!important;
    margin-top: 5px;
  }
}

.input_with_label, .form-check {
  margin: 5px 0px;
}

.hide {
  height: 0px!important;
  /*display: none;*/
}

.form_section p {
  font-size: 18px;
  padding-top: 10px;
  font-family: 'Toyota Type';
  /*margin-bottom: 0px!important;*/
}

#podsumowanie {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 0px!important;
}

#podsumowanie p {
  line-height: 31px;
  font-size: 16px!important;
}

a:hover {
  text-decoration: none;
  color: initial;
}

a {
  text-decoration: underline;
}

.thankyou-hero {
  background: url("../img/hero_desktop.jpg") center / cover;
  height: 360px;
}

.thankyou-hero-cont {
  width: 100%!important;
  --bs-gutter-x: 0!important;
}

@media (max-width: 576px) {
  .thankyou-hero {
    background: url("../img/hero_mob.jpg") center / cover;
    height: 240px;
  }
}

.ty-copy {
  text-align: center;
  padding-top: 40px;
}

.ico_info {
  width: 20px;
  margin-left: 20px;
  vertical-align: sub;
}


//label.form-label.required::after {
//  content: "*";
//}

#form_wniosek input.invisible-field {
  border: 0;
  padding:  0;
  opacity: 0;
  width: 0;
  float: left;
}


ol.legale {
  list-style: none;
  counter-reset: legale_counter;
  padding-left: 30px;
  list-style-position: outside;
  margin-top: -0.5rem
}

.legale li {
  counter-increment: legale_counter;
  padding-bottom: 4px;
  line-height: 150%;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Toyota Type';
}

.legale li::before {
  content: counter(legale_counter) ") ";
  text-align: right!important;
  margin-left: -1.1em;
}
