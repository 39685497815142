@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Light.woff2) format('woff2'),
  url(../fonts/ToyotaType-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Light.woff2) format('woff2'),
  url(../fonts/ToyotaType-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-LightIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-LightIt.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-LightIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-LightIt.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Regular.woff2) format('woff2'),
  url(../fonts/ToyotaType-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Regular.woff2) format('woff2'),
  url(../fonts/ToyotaType-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Italic.woff2) format('woff2'),
  url(../fonts/ToyotaType-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Italic.woff2) format('woff2'),
  url(../fonts/ToyotaType-Italic.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Semibold.woff2) format('woff2'),
  url(../fonts/ToyotaType-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-Semibold.woff2) format('woff2'),
  url(../fonts/ToyotaType-Semibold.woff) format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-SemiboldIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-SemiboldIt.woff) format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type';
  src: url(../fonts/ToyotaType-SemiboldIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-SemiboldIt.woff) format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}



@font-face {
  font-family: 'Toyota Type Book';
  src: url(../fonts/ToyotaType-Book.woff2) format('woff2'),
  url(../fonts/ToyotaType-Book.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type Book';
  src: url(../fonts/ToyotaType-Book.woff2) format('woff2'),
  url(../fonts/ToyotaType-Book.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type Book';
  src: url(../fonts/ToyotaType-BookIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-BookIt.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Toyota Type Book';
  src: url(../fonts/ToyotaType-BookIt.woff2) format('woff2'),
  url(../fonts/ToyotaType-BookIt.woff) format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
