@media only screen and (min-width: 576px) {
  .form__row-inline #form_wniosek {
    /*flex: 0 0 calc(50% - 5px);*/
    /*max-width: calc(50% - 5px);*/
  }
}

@media only screen and (min-width: 576px) {
  .form__row-inline #form_wniosek input {
    width: 100% !important;
  }
}

#form_wniosek {
  margin-bottom: 3.5em;
}

#form_wniosek.is-short input {
  display: inline-block;
  width: auto;
}

#form_wniosek .label, #form_wniosek label {
  display: block;
  font-family: 'Toyota Type Book';
  margin-bottom: 7px;
}

#form_wniosek .label a:hover, #form_wniosek label a:hover {
  color: var(--bs-primary);
}

#form_wniosek .label.form__file, #form_wniosek label.form__file {
  align-items: center;
  /*display: flex;*/
  font-size: 1.4em;
  /*justify-content: center;*/
  min-height: 47px;
}

@media only screen and (min-width: 992px) {
  #form_wniosek .label.form__file, #form_wniosek label.form__file {
    /*font-size: 1.8em;*/
  }
}

#form_wniosek input, #form_wniosek select {
  border: 1px solid var(--bs-info);
  border-radius: 2px;
  display: block;
  font-family: 'Toyota Type';
  /*box-shadow: 0px 0px 7px 0px var(--bs-light);*/
  /*font-size: 1.8em;*/
  /*padding: 9px 15px;*/
  /*width: 100%;*/
}

.form .op-0 {
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  white-space: nowrap;
}

.form__file {
  background-color: var(--bs-secondary);
  border: 1px solid var(--bs-secondary);
  border-radius: 2px;
  color: var(--bs-white);
  cursor: pointer;
  display: block;
  font-family: 'Toyota Type';
  font-size: 1.8em;
  overflow: hidden;
  padding: 9px 15px;
  position: relative;
  text-align: center;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  width: 100%;
}

.form__file:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form__file input {
  cursor: pointer;
  height: 1px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  width: 1px;
}

.form__radiobox {
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 3px;
  padding-left: 35px;
  position: relative;
}

.form__radiobox label {
  cursor: pointer;
  pointer-events: all;
  position: relative;
}

.form__radiobox label:before {
  background-color: var(--bs-white);
  border: 1px solid var(--bs-secondary);
  border-radius: 50%;
  content: "";
  display: block;
  height: 25px;
  left: -35px;
  position: absolute;
  top: 3px;
  width: 25px;
}

.form__radiobox label:after {
  background-color: var(--bs-primary);
  border-radius: 50%;
  content: "";
  display: none;
  height: 10px;
  left: -22px;
  position: absolute;
  top: 11px;
  transform: translate(-50%);
  width: 10px;
}

.form__radiobox input {
  cursor: pointer;
  height: 1px;
  left: -1px;
  opacity: 0;
  position: absolute;
  top: -1px;
  width: 1px;
}

.form__radiobox input:checked + label:after {
  display: block;
}


#form_wniosek .form-check-label {
  display: inline-block!important;
}

form {

  .form-check {
    cursor: default!important;
  }

  .form-check {
    cursor: pointer;
    overflow: hidden;
    padding-bottom: 3px;
    padding-left: 35px;
    position: relative;
    color: var(--bs-gray-300);
  }

  .form-check label {
    cursor: pointer;
    pointer-events: all;
    position: relative;
    font-size: 16px;
    color: var(--bs-secondary);
    line-height: 140%;
    font-family: 'Toyota Type Book';
  }

  .form-check label:before {
    background-color: var(--bs-white);
    border: 1px solid var(--bs-secondary);
    content: "";
    display: block;
    height: 25px;
    left: -35px;
    position: absolute;
    /*top: 3px;*/
    width: 25px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(41,41,48,0.29);
  }

  .form-check label:after {
    border: solid var(--bs-primary);
    border-width: 0 3px 3px 0;
    content: "";
    display: none;
    height: 13px;
    left: -25px;
    position: absolute;
    top: 5px;
    transform: rotate(45deg);
    width: 5px;
  }

  .form-check input {
    cursor: pointer;
    height: 1px;
    left: -1px;
    opacity: 0;
    position: absolute;
    top: -1px;
    width: 1px;
  }

  .form-check input:checked + label:after {
    display: block;
  }
}



#form_wniosek .form-check-label {
  font-size: 15px;
}

#form_wniosek .input_with_label .form-control {
  padding-top: 0.4rem;
  padding-bottom: 0.6rem;
}

#form_wniosek .form-check label:before {
  box-shadow: none!important;
  border-color: var(--bs-info);
  border-radius: 4px;
  height: 20px;
  left: -34px;
  top: 2px;
  width: 20px;
}

#form_wniosek .form-check label:after {
  height: 10px;
  left: -27px;
  top: 6px;
  width: 6px;
}

.form__send-form-wrapper .button {
  display: block;
  width: 100%;
}

.input_with_label label {
  font-family: 'Toyota Type Book'!important;
  font-size: 15px;
  margin-bottom: 10px!important;
}

.invalid-feedback {
  padding-bottom: 10px;
}

.input_with_label input {
  /*margin-bottom: 0px;*/
  font-size: 16px;
  margin-bottom: 20px;


  &.is-invalid {
    margin-bottom: 0;
  }

  top: -4px;
}

.form-control:focus {
  border-color: rgba(80,80,80,0.62)!important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 2px 8px var(--bs-light);
  /*box-shadow: 0px 0px 7px 0px var(--bs-light);*/
}

